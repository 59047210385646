<template>
  <page-base :show-btn="true" @save="save" @submit="submit">
    <div class="form-page" v-if="show">
      <data-form
        :need-del="!formPage.isNew"
        :source-data="formPage.sourceData"
        :draft-data="formPage.draftData"
        :form-parms="formParms"
        @formChange="formChange"
        @del="del"
        ref="dataForm"
        :allData="formPage.formDataItem"
        :isDel="formPage.isDel"
        :delStatus="delStatus"
        :onChange="formPage.onChange"
        :wait-data="formPage.waitData"
        :is-absolut="true"
        :dataFileParm="formPage.dataFileParm"
        :data-type="dataType"
        :wait-keys="formPage.waitKeys">
      </data-form>
    </div>
  </page-base>
</template>

<script>
import PageBase from './pageBase'
import DataForm from './dataForm'

import {
  dataAppleRequest
} from '@/api'

export default {
  components: {
    PageBase,
    DataForm
  },
  watch: {
    formPage: {
      handler () {
        this.show = false
        this.changed = false
        this.formData = null
        this.updateFormParms()
        this.isDel = this.formPage ? this.formPage.isDel : false
        this.isPass = this.formPage && this.formPage.formDataItem && true
        this.$nextTick(() => {
          this.show = true
        })
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      formParms: [],
      formData: null,
      show: false,
      isPass: false,
      changed: false,
      isDel: this.formPage ? this.formPage.isDel : false
    }
  },
  computed: {
    delStatus () {
      let data = null
      if (this.isDel) {
        if (this.formPage.formDataItem.edit && this.formPage.formDataItem.edit.id) {
          data = '存'
          if (this.formPage.formDataItem.modifyApply && this.formPage.formDataItem.modifyApply.id) {
            data = '提'
          }
        }
      }
      return data
    },
    formPage () {
      return this.$store.getters['ModifyApply/formPage']
    },
    workerId () {
      return this.$store.getters['ModifyApply/workerId']
    },
    dataType () {
      return this.$store.getters['ModifyApply/currentMenu'].dataType
    }
  },
  methods: {
    updateFormParms () {
      if (this.formPage) {
        this.formParms = this.formPage.formParms.map(v => {
          if (['select', 'multipleChoose'].includes(v.type)) {
            if (v.selectDatas) {
              v.columns = v.selectDatas
            } else {
              v.getColumns = v.getSelectDatas
            }
            // v.columns = (v.selectDatas ? v.selectDatas : v.getSelectDatas(this.formPage.draftData)).map(c => ({value: c.key, label: c.label}))
          }
          if (v.fmDisabled || v.getFmDisabled) {
            v.disabled = v.fmDisabled ? v.fmDisabled : v.getFmDisabled(this.formPage.draftData)
          }
          return v
        })
      } else {
        this.formParms = []
      }
    },
    del (isDel) {
      this.isDel = isDel
      this.changed = true
    },
    formChange (data) {
      this.isPass = data.pass
      this.formData = data.data
      this.changed = true
    },
    async save (type) {
      if (!this.isDel && this.changed && !this.isPass) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        return
      }
      if (type !== 'submit') {
        if (!this.changed) {
          this.$notice.info({
            title: '系统提示',
            desc: '无数据更改'
          })
          return
        }
      }

      let dataParm = this.formData || this.formPage.draftData

      if (!this.formPage.formDataItem) {
        await this.formPage.addEdit(dataParm)
      } else {
        dataParm.isDel = this.isDel
        await this.formPage.saveEdit(dataParm)
      }
      if (type !== 'submit') {
        this.$notice.info({
          title: '系统提示',
          desc: '保存成功'
        })
      }
      if (!this.formPage.draftId || this.isDel) {
        if (type !== 'submit') {
          this.$router.go(-1)
        }
      }
    },
    async submit () {
      await this.save('submit')
      await dataAppleRequest.submitByWorkerId(this.workerId, {
        dataType: this.dataType
      })
      this.$notice.info({
        title: '系统提示',
        desc: '提交成功'
      })
      this.$refs.dataForm.reloadFile()
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scope>
.form-page {
  width: calc(100% - 50px);
  height: calc(100% - 20px);
  padding: 18px 25px 0;
}
</style>